import 'bootstrap'
import WOW from 'wow.js'
import 'owl.carousel'
import { CountUp } from 'countup.js'
import Rellax from "rellax"

// JavaScript
$(document).ready( function () {

  $(window).on('scroll',function(){

  // transparent header
    var scroll = $(window).scrollTop();
    if(scroll > 100){
      $('.header').addClass("rwd-header");
    }else{
      $('.header').removeClass("rwd-header");
    }
  })

  //countup.js
  var notYet = true
  $(window).on('scroll',function(){

    if(notYet){
      var countUp = new CountUp('exp-years', 15, 3000, { enableScrollSpy: true });
      countUp.start();

      var countUp2 = new CountUp("project", 300, 3000, { enableScrollSpy: true });
      countUp2.start();

      var countUp3 = new CountUp("industry", 1000, 3000, { enableScrollSpy: true });
      countUp3.start();
      notYet = false;
    }
  })

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // owlCarousel
  $(".showcase").owlCarousel({
    navText : ["",""],
    rewindNav : true,
    loop: true,
    nav:true,
    animateOut: 'fadeOut',
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 4000,
    items:1
  });

  //index wow
  new WOW().init();

  //index Rellax
  var rellax = new Rellax('.rellax', {
    horizontal: true,
    vertical: true
  });

  // index form style
  $(".contact_us_block .btn").addClass('neon-btn')

} );


